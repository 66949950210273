import React, { useContext } from "react";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ProjectStatus from "./elements/projectStatus";
import ProjectInfo from "./elements/projectInfo";
import ProjectContext from "../store";
import { fetchProject } from "./stages/shared";

const useStyles = makeStyles(() => createStyles({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
    },
    project: {
        flexShrink: 0,
        width: 428,
        marginTop: '64px',
        paddingBottom: '32px',
        color: '#FFFFFF',
        backgroundColor: 'rgba(13, 28, 89, 0.9)',
    },
}));

const ProjectDrawer = (props) => {
    const classes = useStyles();
    const [projectState, projectDispatch] = useContext(ProjectContext);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `project-tab-${index}`,
            'aria-controls': `project-tabpanel-${index}`,
        };
    }
    const projectId = props.projectId;
    // console.log('projectState', projectState);
    if (!projectState.project && projectState.projectId) {
        fetchProject(projectId, projectDispatch);
    }
    if (projectId) {
        return (
            <div className={classes.root}>
                <main className={classes.content}>
                    {props.children}
                </main>
                <div className={classes.project}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Project Status" {...a11yProps(0)} />
                    <Tab label="Project Info" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                {value===0 && <ProjectStatus project={projectState.project}/>}
                {value===1 && <ProjectInfo project={projectState.project}/>}
                </div>
            </div>
        );
    }
    return props.children;
};

export default ProjectDrawer;
