import React, {useReducer} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Drawer from "./components/Drawer";
import Account from './components/account';
import Project from './components/project';
import ProjectsList from './components/projectsList';
import Analysis from './components/stages/Analysis';
import Proposal from './components/stages/Proposal';
import Negotiation from './components/stages/Negotiation';
import ClosedWon from './components/stages/ClosedWon';
import KickOff from './components/stages/KickOff';
import InFlight from './components/stages/InFlight';
import CloseOut from './components/stages/CloseOut';
import Lost from './components/stages/Lost';
import Parked from "./components/stages/Parked";
import Qualification from './components/stages/Qualification';
import Systems from './components/Systems';
import Financials from './components/Financials';
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {green} from "@material-ui/core/colors";
import Header from "./components/Header";
import Login from "./components/Login";
import UserState from "./states/UserState";
import {ToastContainer} from "react-toastify";
import {initialProjectState, projectReducer} from './reducers/projectReducer';
import {Provider} from "./store";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {currentEnv} from "./context/host";
import ProjectDrawer from "./components/projectDrawer";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: currentEnv() === 'production' ? '#1a46ff' : '#ff5630' ,
            contrastText: '#fff',
        },
        secondary: green
    },
    typography: {
        useNextVariants: true,
    },
});

function App() {
    const useProjectState = useReducer(projectReducer, initialProjectState);
    const match = window.location.pathname.match(/\/projects\/(\d+)/);
    const projectId = match ? match[1] : null;
    if (UserState.token) {
        return (
            <Provider value={useProjectState}>
                <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Router>
                            <div style={{display:'flex'}}>
                                <Header/>
                                <ToastContainer/>
                                { projectId && <Drawer /> }
                                <Route exact path="/" component={ProjectsList} />
                                <Route exact path="/accounts/:id" component={Account} />
                                <ProjectDrawer projectId={projectId}>
                                    <Route exact path="/projects/:id" component={Project} />
                                    <Route path="/projects/:id/qualification" component={Qualification} />
                                    <Route path="/projects/:id/analysis" component={Analysis} />
                                    <Route path="/projects/:id/proposal" component={Proposal} />
                                    <Route path="/projects/:id/negotiation" component={Negotiation} />
                                    <Route path="/projects/:id/closed-won" component={ClosedWon} />
                                    <Route path="/projects/:id/kick-off" component={KickOff} />
                                    <Route path="/projects/:id/in-flight" component={InFlight} />
                                    <Route path="/projects/:id/close-out" component={CloseOut} />
                                    <Route path="/projects/:id/lost" component={Lost} />
                                    <Route path="/projects/:id/parked" component={Parked} />
                                    <Route path="/projects/:id/systems" component={Systems} />
                                    <Route path="/projects/:id/financials" component={Financials} />
                                </ProjectDrawer>
                            </div>
                        </Router>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </Provider>
        );
    } else {
        return (
            <Provider value={useProjectState}>
                <MuiThemeProvider theme={theme}>
                    <Router>
                        <Header/>
                        <ToastContainer/>
                        <Route exact path="/" component={Login}/>
                        <Route exact path="/projects/:id/" component={Login} />
                    </Router>
                </MuiThemeProvider>
            </Provider>
        );
    }
}

export default App;
