import React, { useContext, useEffect, useState } from "react";
import Loader from 'react-loader-spinner';
import { Link } from "react-router-dom";
import clsx from 'clsx';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from "@material-ui/icons/Close";
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from '@material-ui/core/IconButton';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Paper from '@material-ui/core/Paper/index';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";

import { getBackendURL } from "../context/host"
import { getProjectFromUrlAnchor, updateProjectUrlAnchor } from "../utils";
import { STAGES } from "../constants";
import LocalStorageState from '../states/LocalStorageState'
import ProjectContext from "../store";
import UserState from '../states/UserState'
import ProjectDetails from "./elements/projectDetails";

const backendURL = getBackendURL();
const drawerWidth = 280;

const ROWS_PER_PAGE = 25;
const OFFICES = ['UK', 'NORDICS', 'Germany', 'Switzerland'];
const FIELDS = {
    'stage': 'Stage',
    'commercial_lead': 'Commercial Lead',
    'engagement_lead': 'Engagement Lead',
    'technical_lead': 'Technical Lead',
    'project_manager': 'Project Manager',
    'amount': 'Amount',
    'project_type': 'Project Type',
    'project_code': 'Project Code',
    'project_status': 'Project Status',
    'start_date': 'Start Date',
    'end_date_estimated': 'End Date Estimated',
    'end_date_actual': 'End Date Actual',
    'close_date': 'Close-Out Date',
    'budget_hours_planned': 'Budget Estimated',
    'budget_hours_actuals': 'Budget Actual'
};

const SELECTED_FIELDS = ['stage'];
const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    toolbar: theme.mixins.toolbar,
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    cellPadding: {
        padding: "12px 16px",
    },
    // Drawer with Project Details.
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#FEFDFC',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
}));

let searchTimeout = 0;

const ProjectList = () => {
    const [, projectDispatch] = useContext(ProjectContext);
    projectDispatch({type: 'unset'});

    const [projectListFields, setProjectListFields] = LocalStorageState(SELECTED_FIELDS, "projectListFields");

    const params = {page: 1};
    const search = window.location.search.split('?')[1];
    const pairs = search ? search.split('&') : [];
    for (let pair of pairs) {
        const values = pair.split('=');
        params[values[0]] = decodeURI(values[1]);
    }

    const queryState = {
        error: null,
        isLoaded: false,
        count: 0,
        items: [],
        page: parseInt(params.page) - 1,
        q: params.q || '',
        selectedStages: params.stages ? params.stages.split(',') : [],
        selectedOffices: params.offices ? params.offices.split(',') : [],
        account: params.account || '',
        order: 'desc',
        orderBy: ''
    };

    const [state, setState] = useState(queryState);

    useEffect(() => fetchProjects(state.q),
        [state.selectedStages, state.selectedOffices, state.page, state.order, state.orderBy]);

    const handleChangePage = (event, newPage) => {
        if (state.isLoaded) {
            setState(state => ({...state, isLoaded: false, page: newPage}));
        }
    };

    const handleSort = (orderBy) => () => {
        if (state.isLoaded) {
            const isAsc = state.orderBy === orderBy && state.order === 'asc';
            setState(state => ({...state, isLoaded: false, page: 0, order: isAsc ? 'desc' : 'asc', orderBy: orderBy}));
        }
    };

    const handleSearch = (event) => {
        // use timer in free text search to search 500ms after the user stop typing
        const q = event.target.value;
        setState(state => ({...state, account: '', isLoaded: false, q: q, page: 0}));
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        searchTimeout = setTimeout(() => {
            fetchProjects(q)
        }, 500);
    };

    const handleCheckboxes = (event, field) => {
        if (state.isLoaded) {
            const name = event.target.value;
            let values;
            if (event.target.checked) {
                values = [...state[field], name];
            } else {
                values = state[field].filter(item => item !== name);
            }
            setState(state => ({...state, isLoaded: false, page: 0, [field]: values}));
        }
    };

    const openMenu = (event) => {
        setState({...state, anchorEl: event.currentTarget})
    };

    const closeMenu = () => {
        setState({...state, anchorEl: null})
    };

    const removeProjectListField = name => {
        const fields = projectListFields.filter(field => field !== name)
        setProjectListFields(fields)
    };

    const toggleProjectListField = event => {
        const name = event.target.value;
        let fields
        if (event.target.checked) {
            fields = [...projectListFields, name];
        } else {
            fields = projectListFields.filter(field => field !== name);
        }
        setProjectListFields(fields)
    };

    const fetchProjects = (q) => {
        const url = `${backendURL}projects/`;
        const params = `?q=${q}&page_size=${ROWS_PER_PAGE}&page=${parseInt(state.page + 1)}&stages=${state.selectedStages}&offices=${state.selectedOffices}&account=${state.account}&order_by=${state.orderBy}&order=${state.order}`;
        fetch(url + params, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Token ' + UserState.token
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    window.history.pushState(null, null, params);
                    setState(state => ({...state, isLoaded: true, count: result.count, items: result.results}));
                    document.getElementById('outlined-search').focus();
                },
                (error) => {
                    setState(state => ({...state, isLoaded: true, ...error}));
                }
            );
    };

    const classes = useStyles();
    const {error, isLoaded, items, count, page, q} = state;
    const selectedProject = getProjectFromUrlAnchor();

    if (error) {
        return (
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <div>Error: {error.message}</div>
            </main>
        );
    } else {
        return (
            <div className={classes.root}>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: Boolean(selectedProject),
                    })}
                >
                    <div className={classes.toolbar} style={{minHeight: "20px"}}/>
                    <TextField
                        id="outlined-search"
                        label="Search"
                        value={q}
                        type="search"
                        margin="normal"
                        variant="outlined"
                        display="inline"
                        onChange={handleSearch}
                        fullWidth={true}
                        autoFocus
                    />
                    <br/>
                    <FormControl component="fieldset" fullWidth={true} style={{marginLeft: "20px"}}>
                        <FormGroup row>
                            {STAGES.map((stage) => (
                                <FormControlLabel
                                    key={stage.order}
                                    control={
                                        <Checkbox
                                            checked={state.selectedStages.includes(stage.label)}
                                            value={stage.label}
                                            color={"primary"}
                                            onChange={(e) => handleCheckboxes(e, 'selectedStages')}
                                        />
                                    }
                                    label={stage.label}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>

                    <FormControl component="fieldset" fullWidth={true} style={{marginLeft: "20px"}}>
                        <FormGroup row>
                            {OFFICES.map((office, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={state.selectedOffices.includes(office)}
                                            value={office}
                                            color={"primary"}
                                            onChange={(e) => handleCheckboxes(e, 'selectedOffices')}
                                        />
                                    }
                                    label={office}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>

                    <Paper>
                        <Menu
                            id="columns-menu"
                            anchorEl={state.anchorEl}
                            keepMounted
                            open={Boolean(state.anchorEl)}
                            onClose={closeMenu}
                        >
                            {Object.entries(FIELDS).map(([key, value]) => (
                                <MenuItem key={key}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={projectListFields.includes(key)}
                                                value={key}
                                                color={"primary"}
                                                onChange={toggleProjectListField}
                                            />
                                        }
                                        label={value}
                                    />
                                </MenuItem>
                            ))}
                        </Menu>

                        <div style={{float: 'right'}}>
                            <Button onClick={openMenu}>
                                Configure columns <MoreVertIcon/>
                            </Button>
                        </div>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {['office', 'account', 'project'].map(headCell => (
                                            <TableCell classes={{root: classes.cellPadding}} key={headCell} style={{
                                                textTransform: 'capitalize',
                                                textAlign: headCell === 'amount' ? 'right' : 'left'
                                            }}>
                                                <TableSortLabel
                                                    active={state.orderBy === headCell}
                                                    direction={state.orderBy === headCell ? state.order : 'asc'}
                                                    onClick={handleSort(headCell)}
                                                >
                                                    {headCell.replace('_', ' ')}
                                                    {state.orderBy === headCell ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                        {projectListFields.map(field => (
                                            <TableCell classes={{root: classes.cellPadding}} key={field} style={{
                                                textTransform: 'capitalize',
                                                textAlign: field === 'amount' ? 'right' : 'left'
                                            }}>
                                                <TableSortLabel
                                                    active={state.orderBy === field}
                                                    direction={state.orderBy === field ? state.order : 'asc'}
                                                    onClick={handleSort(field)}
                                                >
                                                    {FIELDS[field]}
                                                    <span onClick={() => removeProjectListField(field)} style={{verticalAlign: "baseline"}}>
                                                    <CloseIcon fontSize="small" style={{height: '10px'}}/>
                                                    </span>
                                                    {state.orderBy === field ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {isLoaded &&
                                <TableBody>
                                    {items != null && items.map(project => (
                                        <TableRow
                                            key={project.id}
                                            hover={true}
                                            onClick={() => { updateProjectUrlAnchor(project.id === selectedProject? null: project.id) }}
                                            selected={project.id === selectedProject}
                                        >
                                            <TableCell classes={{root: classes.cellPadding}}>{project.office}</TableCell>
                                            <TableCell classes={{root: classes.cellPadding}}>
                                                <Link to={'/accounts/' + project.account_id}>{project.account}</Link>
                                            </TableCell>
                                            <TableCell classes={{root: classes.cellPadding}}>
                                                <Link to={'/projects/' + project.id}>{project.name}</Link>
                                            </TableCell>
                                            {projectListFields.map(field => {
                                                return (field === 'amount') ?
                                                    <TableCell key={field} classes={{root: classes.cellPadding}}
                                                            style={{textAlign: "right"}}>
                                                        {Number(project.amount).toLocaleString('en',
                                                            {
                                                                style: 'currency',
                                                                currency: project.currency,
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                            })}
                                                    </TableCell>
                                                    :
                                                    <TableCell key={`cell-${field}`}
                                                            classes={{root: classes.cellPadding}}>{project[field]}</TableCell>
                                            })}

                                        </TableRow>
                                    ))}
                                </TableBody>
                                }
                                {isLoaded &&
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[ROWS_PER_PAGE]}
                                            colSpan={1}
                                            count={count}
                                            rowsPerPage={ROWS_PER_PAGE}
                                            page={page}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            onChangePage={handleChangePage}
                                        />
                                    </TableRow>
                                </TableFooter>
                                }
                            </Table>
                        </TableContainer>
                        {!isLoaded &&
                        <div style={{
                            width: "100%",
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        >
                            <Loader type="ThreeDots" color="#1a46ff"/>
                        </div>
                        }
                    </Paper>
                </main>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={Boolean(selectedProject)}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={() => updateProjectUrlAnchor(null)}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <ProjectDetails project={items.find(p => p.id === selectedProject)} />
                </Drawer>
            </div>
        );
    }
};

export default ProjectList;
