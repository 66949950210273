/**
 * Read project ID from URL Anchor if available.
 * @return {int|null} Return value description.
 */
export function getProjectFromUrlAnchor() {
    return window.location.hash? parseInt(window.location.hash.replace('#', '')): null;
}

/**
 * Update URL Anchor if a new project is selected on the list.
 * @param {int|null} projectId      Project Id or null.
 */
export function updateProjectUrlAnchor(projectId) {
    window.location.hash = projectId || '';
}
