import React, {Component} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {GoogleLogin} from 'react-google-login';
import {withStyles} from "@material-ui/core";
import UserState from "../states/UserState";
import {getBackendURL} from "../context/host";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const backendURL = getBackendURL();

const styles = theme => ({
    login: {
        textAlign: 'center',
        marginTop: '100px'
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
});

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_loaded: true,
        }
    }

    handleGoogleResponse = (response) => {
        let ok;
        let data;
        this.setState({is_loaded: false});
        if (
            response.profileObj.email.includes("@datatonic.com") ||
            response.profileObj.email.includes("@knownx.ai")
        ) {
            data = {'id_token': response.getAuthResponse().id_token};
            fetch(`${backendURL}validate-google-token/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    ok = response.ok;
                    return response.json();
                })
                .then((responseData) => {
                    if (ok) {
                        UserState.setToken(responseData.token);
                        UserState.setRole(responseData.superuser? 'superuser': responseData.admin ? 'admin' : 'user');
                        UserState.setUser(response.profileObj.name);
                        window.location.reload();
                    }
                    else {
                        toast.error(responseData.error, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    }
                });
        } else {
            toast.error('Invalid user', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    };

    render() {
        const {classes} = this.props;
        if (!this.state.is_loaded) {
            return (
                <main className={classes.content}>
                    <div className={classes.toolbar}/>

                    <Paper className={classes.paper}>
                        <Typography variant="h5">
                            Loading ...
                        </Typography>
                    </Paper>
                </main>
            )
        } else {
            return (
                <main className={classes.content}>
                    <div className={this.props.classes.login}>
                        <GoogleLogin
                            clientId="604074457999-bmgj95packlji0298slku056p4vbhien.apps.googleusercontent.com"
                            buttonText="Login with google"
                            onSuccess={this.handleGoogleResponse}
                            onFailure={this.handleGoogleResponse}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </main>
            )
        }
    }
}

export default withStyles(styles, {withTheme: true})(Login);
