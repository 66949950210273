import React from "react";
import clsx from 'clsx';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import ProjectProgress from './projectProgress';


const useStyles = makeStyles(() => createStyles({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: 'white',
        padding: '1em',
    },
    statusBlock: {
        color: 'black',
        backgroundColor: '#EFF2FF',
        padding: '1em',
        margin: '3em 0',
    },
    bold: {
        fontWeight: 600
    },
    leftMargin: {
        marginLeft: '0.6em',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
}));


const ProjectStatus = (props) => {
    const classes = useStyles();
    const project = props.project;
    if (!project) return null;
    console.log('project', project);
    return (
        <div className={classes.root}>
            <ProjectProgress project={project}/>
            <div className={classes.statusBlock}>
                <div className={classes.row}>
                    <div>
                        <div>
                            <Typography variant="body1" display="inline">
                                Start date
                            </Typography>
                            <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                {project['start_date'] || 'n/a'}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" display="inline">
                                Close date
                            </Typography>
                            <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                {project['close_date'] || 'n/a'}
                            </Typography>
                        </div>
                        <Typography variant="body1" paragraph={true}>
                            Deliverable Description
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body1" display="inline">
                            Status
                        </Typography>
                        <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                            {project['project_status'] || 'n/a'}
                        </Typography>
                    </div>
                </div>
                <div className={classes.row}>
                    <div>
                        <div>
                            <Typography variant="body1" display="inline">
                                Value
                            </Typography>
                            <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                {(project['amount'] || 'n/a') + ' ' + project['currency']}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" display="inline">
                                PO
                            </Typography>
                            <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                n/a
                            </Typography>
                        </div>
                    </div>
                    <div>
                        <Typography variant="body1" display="inline">
                            Invoice number
                        </Typography>
                        <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                            n/a
                        </Typography>
                    </div>
                </div>
            </div>
            <Typography variant="body1">
                {project['stage'] || 'n/a'}
            </Typography>
        </div>
    );
};

export default ProjectStatus;
