import React from "react";

import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles(() => createStyles({
    drawerContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: 'white',
    },
    drawerContentPadding: {
        padding: '1em',
    },
    drawerTextFieldResize: {
        fontSize: '80%',
    },
    icon: {
        width: '40px',
        height: '40px',
        marginRight: '4px',
        backgroundColor: 'red',
    },
}));


const ProjectDetails = (props) => {
    const classes = useStyles();
    const project = props.project;
    return (
        <div className={classes.drawerContent}>
            <div className={classes.drawerContentPadding}>
                {Boolean(project) &&
                    [
                        {label: 'Name', attribute: 'name'},
                        {label: 'Office', attribute: 'office'},
                        {label: 'Start (close)', attribute: 'start_date', attribute2: 'close_date'},
                        {label: 'Status', attribute: 'project_status'},
                        {label: 'Account', attribute: 'account'},
                        {label: 'Code (type)', attribute: 'project_code', attribute2: 'project_type'},
                        {label: 'Value (amount)', attribute: 'amount', attribute2: 'currency'},
                        {label: 'Hours actual (planned)', attribute: 'budget_hours_actuals', attribute2: 'budget_hours_planned'},
                        {label: 'Commercial lead', attribute: 'commercial_lead'},
                        {label: 'Engagement lead', attribute: 'engagement_lead'},
                        {label: 'Manager', attribute: 'project_manager'},
                        {label: 'Actual end date (estimated)', attribute: 'end_date_actual', attribute2: 'end_date_estimated'},
                    ].map((projectItem, index) => {

                        return project? (
                            <TextField
                                key={index}
                                label={projectItem.label}
                                value={(project[projectItem.attribute] + (projectItem.attribute2? ` (${project[projectItem.attribute2]})`: '')).replaceAll('null', 'n/a')}
                                variant="outlined"
                                margin="dense"
                                fullWidth={true}
                                disabled={true}
                                InputProps={{
                                    classes: {
                                        input: classes.drawerTextFieldResize,
                                    },
                                }}
                            />
                        ): null;
                    })
                }
            </div>
        </div>
    );
};

export default ProjectDetails;
