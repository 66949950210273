import React from "react";

import { createStyles, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: 'white',
    },
    progressContent: {

    },
    hoursPlanned: {
        textAlign: 'right',
    },
    progressWrap: {
        background: '#FFF',
        position: 'relative',
        width: '100%',
        height: 50,
    },
    progressBar: {
        background: theme.palette.primary.main,
        left: 0,
        position: 'absolute',
        top: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '1em',
        fontWeight: 600,
    },
    progressDescription: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}));


const ProjectProgress = (props) => {
    const classes = useStyles();
    const project = props.project;
    if (!project) return null;
    return (
        <div className={classes.root}>
            <div className={classes.progressContent}>
                <div className={classes.hoursPlanned}>
                    {project['budget_hours_planned']}
                </div>
                <div className={classes.progressWrap}>
                    <div className={classes.progressBar} style={{ width: `${((project['budget_hours_actuals'] || 1)/project['budget_hours_planned'] || 1) * 100}%`, }}>
                        {project['budget_hours_actuals']}h
                    </div>
                </div>
                <div className={classes.progressDescription}>
                    <div>Start</div>
                    <div>MS1</div>
                    <div>MS2</div>
                    <div>End</div>
                </div>
            </div>
        </div>
    );
};

export default ProjectProgress;
