import React from "react";
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { white } from "ansi-colors";
import * as shared from "../stages/shared";

const useStyles = makeStyles(() => createStyles({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: 'white',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    content: {
        padding: '1em',
    },
    section: {
        margin: '3em 0',
    },
    twopanels: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '2em',
    },
    leftpanel: {
        flexBasis: '49%',
    },
    rightpanel: {
        flexBasis: '49%',
    },
    icon: {
        width: '40px',
        height: '40px',
        marginLeft: '0.7em',
        backgroundColor: 'rgba(0,0,0,0)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
        backgroundSize: 'contain',
    },
    iconCalculator: {
        backgroundImage: 'url("/static/img/Calculator_Logo.png")',
    },
    iconContract: {
        backgroundImage: 'url("/static/img/Contract_Logo.png")',
    },
    iconGoogle: {
        backgroundImage: 'url("/static/img/Google_Logo.png")',
    },
    iconPipedrive: {
        backgroundImage: 'url("/static/img/Pipedrive_Logo.png")',
    },
    bold: {
        fontWeight: 600
    },
    leftMargin: {
        marginLeft: '0.6em',
    },
    flagIcon: {
        display: 'inline-block',
        width: '20px',
        height: '20px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
        backgroundSize: 'contain',
        marginLeft: '0.7em',
    },
    flagIconDach: {
        backgroundImage: 'url("/static/img/Flag_DACH.png")',
    },
    flagIconNordic: {
        backgroundImage: 'url("/static/img/Flag_Nordics.png")',
    },
    flagIconUK: {
        backgroundImage: 'url("/static/img/Flag_UK.png")',
    },
    settingsIcon: {
        color: white,
    },
    whiteColor: {
        color: 'white',
    },
    textFieldOnputProps: {
        backgroundColor: 'white',
    },
}));


const ProjectInfo = (props) => {
    const classes = useStyles();
    const project = props.project;
    const [edited, setEdited] = React.useState(0);
    const [state, setState] = React.useState(project);
    if (!project || !("name" in project)) return null;

    const handleSubmit = (event) => {
        console.log('state', state);
        shared.handleSubmit(event, { ...state, stage_name: 'projects', project_updates_text: null, interested: null, }).then((response) => {
            setState(state => ({...state, ...response}));
            setEdited(false);
        })
    };

    const flagIcon = (flagName) => {
        let flag = flagName;
        switch(flagName) {
            case 'UK':
                flag = <div className={clsx(classes.flagIcon, classes.flagIconUK)} />;
                break;
            case 'DACH':
                flag = <div className={clsx(classes.flagIcon, classes.flagIconDach)} />;
                break;
            case 'NORDICS':
                flag = <div className={clsx(classes.flagIcon, classes.flagIconNordic)} />;
                break;
        }
        return flag;
    }

    // console.log('project:', project);
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.header}>
                    {edited?
                        <TextField
                            color="primary"
                            label="Project name"
                            value={state['name']}
                            variant="filled"
                            fullWidth={true}
                            inputProps={{ className: classes.textFieldOnputProps }}
                            onChange={(e) => { setState({ ...state,  name: e.target.value })}}
                        />:
                        <div>
                            <Typography variant="body1">
                                Project Name
                            </Typography>
                            <Typography variant="body1" className={classes.bold}>
                                {project['name']}
                            </Typography>
                        </div>
                    }
                    {edited?
                        <div>
                            <Button variant="contained" color="primary" onClick={() => setEdited(false)} style={{ margin: '0 .5em .5em' }}>Cancel</Button>
                            <Button variant="contained" color="secondary" onClick={handleSubmit} style={{ margin: '.5em' }}>Update</Button>
                        </div>:
                        <IconButton className={classes.whiteColor} aria-label="settings">
                            <SettingsIcon fontSize="small" color="inherit" onClick={() => setEdited(true)}/>
                        </IconButton>
                    }
                </div>
                <div className={clsx(classes.twopanels, classes.section)}>
                    <div className={classes.leftpanel}>
                        {edited?
                            <TextField
                                color="primary"
                                label="Parent"
                                value="Project"
                                variant="filled"
                                fullWidth={true}
                                inputProps={{ className: classes.textFieldOnputProps }}
                            />:
                            <div>
                                <Typography variant="body1" display="inline">
                                    Parent
                                </Typography>
                                <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                    Project
                                </Typography>
                            </div>
                        }
                        {edited?
                            <TextField
                                color="primary"
                                label="Project code"
                                value={project['project_code']}
                                variant="filled"
                                fullWidth={true}
                                inputProps={{ className: classes.textFieldOnputProps }}
                                onChange={(e) => { setState({ ...state,  project_code: e.target.value })}}
                            />:
                            <div>
                                <Typography variant="body1" display="inline">
                                    Project code
                                </Typography>
                                <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                    {project['project_code'] || 'n/a'}
                                </Typography>
                            </div>
                        }
                        {edited?
                            <TextField
                                color="primary"
                                label="Project type"
                                value={project['project_type']}
                                variant="filled"
                                fullWidth={true}
                                inputProps={{ className: classes.textFieldOnputProps }}
                                onChange={(e) => { setState({ ...state,  project_type: e.target.value })}}
                            />:
                            <div>
                                <Typography variant="body1" display="inline">
                                    Project type
                                </Typography>
                                <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                    {project['project_type'] || 'n/a'}
                                </Typography>
                            </div>
                        }
                    </div>
                    <div className={classes.rightpanel}>
                        <Typography variant="body1">
                            Children
                        </Typography>
                    </div>
                </div>

                <div className={classes.section}>
                    {edited?
                        <TextField
                            color="primary"
                            label="Office"
                            value={project['office']}
                            variant="filled"
                            fullWidth={true}
                            inputProps={{ className: classes.textFieldOnputProps }}
                            onChange={(e) => { setState({ ...state,  office: e.target.value })}}
                        />:
                        <div>
                            <Typography variant="body1" display="inline">
                                Office
                            </Typography>
                            {flagIcon(project['office'] || 'n/a')}
                        </div>
                    }
                    {edited?
                        <TextField
                            color="primary"
                            label="Value"
                            value={project['amount']}
                            variant="filled"
                            type="number"
                            fullWidth={true}
                            inputProps={{ className: classes.textFieldOnputProps }}
                            onChange={(e) => { setState({ ...state,  amount: e.target.value })}}
                        />:
                        <div>
                            <Typography variant="body1" display="inline">
                                Value
                            </Typography>
                            <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                {(project['amount'] || 'n/a') + ' ' + project['currency']}
                            </Typography>
                        </div>
                    }
                    {!edited &&
                        <div>
                            <Typography variant="body1" display="inline">
                                Weighted value
                            </Typography>
                            <Typography variant="body1" display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                {(project['amount'] || 'n/a') + ' ' + project['currency']}
                            </Typography>
                        </div>
                    }
                </div>

                <div className={classes.section}>
                    {edited?
                        <TextField
                            color="primary"
                            label="Main service"
                            value={project['project_type']}
                            variant="filled"
                            fullWidth={true}
                            inputProps={{ className: classes.textFieldOnputProps }}
                            onChange={(e) => { setState({ ...state,  project_type: e.target.value })}}
                        />:
                        <div>
                            <Typography variant="body1"  display="inline">
                                Main service
                            </Typography>
                            <Typography variant="body1"  display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                {project['project_type'] || 'n/a'}
                            </Typography>
                        </div>
                    }
                    {edited?
                        <TextField
                            color="primary"
                            label="Competencies"
                            value={project['competencies']}
                            variant="filled"
                            fullWidth={true}
                            inputProps={{ className: classes.textFieldOnputProps }}
                            onChange={(e) => { setState({ ...state,  competencies: e.target.value })}}
                        />:
                        <div>
                            <Typography variant="body1"  display="inline">
                                Competencies
                            </Typography>
                            <Typography variant="body1"  display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                {String(project['competencies'] || 'n/a')}
                            </Typography>
                        </div>
                    }
                    {edited?
                        <TextField
                            color="primary"
                            label="Probability"
                            value={project['forecast_probability']}
                            variant="filled"
                            fullWidth={true}
                            inputProps={{ className: classes.textFieldOnputProps }}
                            onChange={(e) => { setState({ ...state,  forecast_probability: e.target.value })}}
                        />:
                        <div>
                            <Typography variant="body1" display="inline">
                                Probability
                            </Typography>
                            <Typography variant="body1"  display="inline" className={clsx(classes.bold, classes.leftMargin)}>
                                {String(project['forecast_probability'] || 'n/a')}
                            </Typography>
                        </div>
                    }
                </div>
                <div className={classes.twopanels}>
                    <div className={classes.leftpanel}>
                        {edited?
                            <TextField
                                color="primary"
                                label="Commercial Lead"
                                value={project['commercial_lead']}
                                variant="filled"
                                fullWidth={true}
                                inputProps={{ className: classes.textFieldOnputProps }}
                                onChange={(e) => { setState({ ...state,  commercial_lead: e.target.value })}}
                            />:
                            <div>
                                <Typography variant="body1">
                                    Commercial Lead
                                </Typography>
                                <Typography variant="body1" className={classes.bold}>
                                    {project['commercial_lead'] || 'n/a'}
                                </Typography>
                            </div>
                        }
                        {edited?
                            <TextField
                                color="primary"
                                label="Project Manager"
                                value={project['project_manager']}
                                variant="filled"
                                fullWidth={true}
                                inputProps={{ className: classes.textFieldOnputProps }}
                                onChange={(e) => { setState({ ...state,  project_manager: e.target.value })}}
                            />:
                            <div>
                                <Typography variant="body1">
                                    Project Manager
                                </Typography>
                                <Typography variant="body1" className={classes.bold}>
                                    {project['project_manager'] || 'n/a'}
                                </Typography>
                            </div>
                        }
                    </div>
                    <div className={classes.rightpanel}>
                    {edited?
                        <TextField
                            color="primary"
                            label="Engagement lead"
                            value={project['engagement_lead']}
                            variant="filled"
                            fullWidth={true}
                            inputProps={{ className: classes.textFieldOnputProps }}
                            onChange={(e) => { setState({ ...state,  engagement_lead: e.target.value })}}
                        />:
                        <div>
                            <Typography variant="body1">
                                Engagement lead
                            </Typography>
                            <Typography variant="body1" className={classes.bold}>
                                {project['engagement_lead'] || 'n/a'}
                            </Typography>
                        </div>
                    }
                    {edited?
                        <TextField
                            color="primary"
                            label="Technical Lead"
                            value={project['technical_lead']}
                            variant="filled"
                            fullWidth={true}
                            inputProps={{ className: classes.textFieldOnputProps }}
                            onChange={(e) => { setState({ ...state,  technical_lead: e.target.value })}}
                        />:
                        <div>
                            <Typography variant="body1">
                                Technical Lead
                            </Typography>
                            <Typography variant="body1" className={classes.bold}>
                                {project['technical_lead'] || 'n/a'}
                            </Typography>
                        </div>
                    }
                    </div>
                </div>
            </div>
            {!edited &&
                <div className={classes.drawerContentPadding}>
                    <Button variant="outlined" className={clsx(classes.icon, classes.iconCalculator)} target="_blank" href={project['harvest_url']} disabled={!project['pipedrive_url']}/>
                    <Button variant="outlined" className={clsx(classes.icon, classes.iconContract)} target="_blank" href={project['harvest_url']} disabled={!project['pipedrive_url']}/>
                    <Button variant="outlined" className={clsx(classes.icon, classes.iconGoogle)} target="_blank" href={project['googledrive_delivery_folder_url']} disabled={!project['googledrive_delivery_folder_url']}/>
                    <Button variant="outlined" className={clsx(classes.icon, classes.iconPipedrive)} target="_blank" href={project['pipedrive_url']} disabled={!project['pipedrive_url']}/>
                </div>
            }
        </div>
    );
};

export default ProjectInfo;